import * as React from "react";

import SEO from "../../components/seo";
import seoImage from "../../images/SIIA_2023_Forum_article.png";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import ArticleComponents, {
  TitleComponent,
  SubTitleComponent,
  ParagraphComponent,
} from "../../components/article";
import { Tag } from "carbon-components-react";
import ExpertButtonComponent from "../../components/expertButton";

const title =
  "LaunchPad's Mike Orth to Speak on Price Transparency at SIIA's Spring Forum";
const subtitle =
  "Mike Orth will provide senior healthcare executives everything they need to know on the evolving regulatory and business environment related to price transparency.";
const alt = "SIIA 2023 Spring Forum";
const date = "January 11, 2023";
const author = "LaunchPad Health";

const SiiaSpringForumArticle = () => {
  return (
    <>
      <SEO
        title={title}
        description={subtitle}
        image={{ src: seoImage, width: 1200, height: 627 }}
      />
      <header>
        <HeaderComponent />
      </header>
      <ExpertButtonComponent />
      <div className="leadSpaceMargin spacing09">
        <div className="bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-5"></div>
            <div className="bx--col-lg-11 bx--col-sm-0">
              <div className="articleHeader"></div>
            </div>
          </div>
          <div className="bx--row">
            <section className="bx--col-lg-3">
              <div className="articleInfoDesktop">
                <h5>Date</h5>
                <body>{date}</body>
              </div>
              <div className="articleInfoDesktop">
                <h5>Author</h5>
                <body>
                  <Tag type="blue" size="md" title="Author">
                    {author}
                  </Tag>
                </body>
              </div>
            </section>
            <div className="bx--col-lg-2"></div>
            <div className="bx--col-lg-9">
              <div className="articleInfoMobile">
                <div>
                  <h5>Date</h5>
                  <body>{date}</body>
                </div>
                <div style={{ paddingLeft: "1rem" }}>
                  <h5>Author</h5>
                  <body>
                    <Tag type="blue" size="md" title="Author">
                      {author}
                    </Tag>
                  </body>
                </div>
              </div>
              <TitleComponent title={title} />
              <SubTitleComponent subtitle={subtitle} />
              <img
                src={seoImage}
                alt={alt}
                style={{ maxWidth: "100%" }}
                className="spacing07"
              />

              <ParagraphComponent
                paragraph={
                  <p className="articleParagraph">
                    The Self-Insurance Institute of America's (SIIA) Spring
                    Forum is expected to be the largest gathering of
                    senior-level self-insurance industry professionals for the
                    first half of 2023. Mike Orth will be speaking on Friday,
                    March 31 at 8:30am ET. This will be the second year in a row
                    that Orth will deliver a presentation to this conference on
                    price transparency.
                  </p>
                }
              />
              <ParagraphComponent
                paragraph={
                  <p className="articleParagraph">
                    To register for the conference, or view conference details,{" "}
                    <a
                      href="https://siiaconferences.org/springforum/2023/index.cfm"
                      target="_blank"
                    >
                      please visit SIIA's registration page.
                    </a>
                  </p>
                }
              />
            </div>
            <div className="bx--col-lg-2"></div>
          </div>
        </div>
      </div>
      <footer>
        <FooterComponent />
      </footer>
    </>
  );
};

export default SiiaSpringForumArticle;
