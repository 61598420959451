import * as React from "react";

const TitleComponent = (props) => {
  return (
    <>
      <h1 className="articleTitle">{props.title}</h1>
    </>
  );
};

const SubTitleComponent = (props) => {
  return (
    <>
      <h4 className="spacing07" style={{ fontSize: "24px" }}>
        {props.subtitle}
      </h4>
    </>
  );
};

const ParagraphComponent = (props) => {
  return (
    <>
      <div>{props.paragraph}</div>
    </>
  );
};

const ParagraphHeaderComponent = (props) => {
  return (
    <>
      <h4 className="spacing06 bold">{props.header}</h4>
    </>
  );
};

export {
  TitleComponent,
  SubTitleComponent,
  ParagraphComponent,
  ParagraphHeaderComponent,
};
